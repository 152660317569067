// Establish the basic building blocks of the project

@import "core/libraries/compass/css3";
@import "core/libraries/compass/typography";
@import "utilities";
@import "grids";
@import "colors";
@import "core/libraries/compass/layout";
@import "core/libraries/arrows";
@import "core/libraries/mixin";

//
// Font Sizes
//

$font-size-xs:      10px; // Avoid fonts this small
$font-size-s:       12px;
$font-size-normal:  14px;
$font-size-md:      18px;
$font-size-l:       21px;
$font-size-xl:      27px;
$font-size-xxl:     32px;
$font-size-xxxl:    40px;
$font-size-xxxxl:   47px;
$font-size-xxxxxl:  60px;

// Font Shortcuts
$fs-xs:     $font-size-xs;
$fs-s:      $font-size-s;
$fs-n:      $font-size-normal;
$fs-m:      $font-size-md;
$fs-l:      $font-size-l;
$fs-xl:     $font-size-xl;
$fs-xxl:    $font-size-xxl;
$fs-xxxl:   $font-size-xxxl;
$fs-xxxxl:  $font-size-xxxxl;
$fs-xxxxxl: $font-size-xxxxxl;

//
// Configure Vertical Rhythm.
//

$base-font-size:    $font-size-normal;  // Font-size of your body copy.
$base-line-height:  11px;               // Making the base-line-height half the height
                                        // of the desired body copy line-height
                                        // helps prevent sparse leading at different font-sizes.

//
// Typography
//

$primary-typeface:    Helvetica, Arial, sans-serif;                    // Body copy font stack.
$secondary-typeface:  BebasNeueRegular, Helvetica, Arial, sans-serif;  // Heading font stack.

@mixin secondary-typeface($font-fam: $secondary-typeface, $opacity: .999) {
  font-family: $font-fam;
  opacity: $opacity;
}

$tertiary-typeface:   $secondary-typeface;                             // Accent font stack. (Defaults to the secondary typeface.)

//
// Colors
//

$red:             #a8000f;
$red-dark:        #7c000a;
$blue-light:      #004a87;
$blue:            #023c72;
$blue-dark:       #01203d;
$orange:          #f2ab01;
$orange-dark:     #7d0006;

$black:           #000;
$grey:            #444;
$grey-light:      #ccc;
$white:           #fff;

$primary-color:   $blue-dark; // Body copy color.
$secondary-color: $orange;      // Link color.
$tertiary-color:  $white;      // Accent color.
$border-color:    $red;      // Border color.

//
// 
//

//
// Buttons
//

$sb-text-style: "none";

//@mixin button($button-color: $rust, $text-color: lighten($off-white, 10%)) {
//  @include sassy-button("flat", 6px, $font-size-md, $button-color, $button-color, $text-color );
//  font-family: $secondary-typeface;
//  padding: .125em 10px;
//}

@mixin button-hover($button-color: $blue, $text-color: $orange) {
  background-color: lighten($button-color, 10%);
  color: $text-color;
}

@mixin button-active($button-color: $blue, $text-color: $orange) {
  background-color: darken($button-color, 15%);
  color: $text-color;
}

//
// Sprites
//

//$icons: sprite-map("icons/*.png");
//
//$icons-spacing: 50px;
//@import "icons/*.png";  // Creates a single sprite file using all pngs
                        // within images/icons.
                        // Usage: @include icons-sprite(filename);
