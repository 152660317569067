/**
 * @file
 * Styles for Status Messages
 */

@import "variables";

// Status
$status-foreground: #4F8A10; // Text, Border
$status-background: #DFF2BF; // Background

// Warning
$warning-foreground: #9F6000; // Text, Border
$warning-background: #FEEFB3; // Background

// Error
$error-foreground: #D8000C; // Text, Border
$error-background: #FFBABA; // Background

// Status, Error, Warning (Defaults)

#messages {
  &.clearfix:after {
    display: none;
  }
}

body.front div.messages {
  margin-right: 0;
}

div.messages {
  margin-right: 20px;
  position: relative;
  background-image: none;
  &:before {
    position: absolute;
    top: 11px;
    left: 12px;
    display: block;
    background-color: transparent;
    font-family: fontello;
    font-size: 25px;
  }
  a {
    color: white;
    border: none;
  }
} 

// DPM
div.messages div.krumo-root a {
  color: #404000;
  text-decoration: blink;
}

// Status

div.status {
  border-color: $status-foreground;
  color: $status-foreground;
  background-color: $status-background;
  &:before {
    content: "C";
  }
}

// Warning

div.warning {
  color: $warning-foreground;
  border-color: $warning-foreground;
  background-color: $warning-background;
  &:before {
    font-size: 22px;
    content: "!";
  }
}

// Error

div.error {
  color: $error-foreground;
  border-color: $error-foreground;
  background-color: $error-background;
  &:before {
    font-size: 22px;
    content: "!";
  }
}

// Full Screen Notification and Animation

#full-screen-notification {
	bottom: 0;
  color: #fff;  
  background-color: rgba(0,0,0,0.9);
  left: -200%; 
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  
  .notification-text {
   	font-family: $secondary-typeface;
  	font-size: $fs-xxxl;
  	margin: -1em auto 0;
  	text-align: center;  
  	@include opacity(0.1);
  	@include single-transition(opacity,1s);
  }
 
  .notification-icon {  
		position: absolute;
		top: 50%;
		font-family: fontello;
		font-size: 75px;
		line-height: 75px;
		left: 50%;
		width: 75px;
		height: 75px;
		margin: -140px -37px 0;
		text-align: center;
  	@include opacity(0.1);
  	@include single-transition(opacity,1s);
  }
  
  &.active {  
  	height: 100%;
  	left: 0;
  	z-index: 3;

  	.notification-text {  		
  		@include opacity(1.0);  		
  	}
  	
  	&:before {
  		display: block;
  		height: 50%;
  		width: 100%;    		
  		content: "";
    }
  }
}

#full-screen-notification {
	&.active {
		.notification-icon {			
			-webkit-animation: rotate-360 1.5s linear infinite;
			-moz-animation: rotate-360 1.5s linear infinite;
			animation: rotate-360 1.5s linear infinite;
			@include opacity(1.0); 			
		}
	}
}

@-moz-keyframes rotate-360 { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes rotate-360 { 100% { -webkit-transform: rotate(360deg); } }
@keyframes rotate-360 { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }